import React, { useEffect, useState } from "react";

import Spinner from "components/common/spinner";
import PurchaseBtn from "components/common/course/purchase";
import Collapsible from "components/common/collapsible";
import { Select } from "components/common/form";

import { CourseAPI } from "api";

import ResourceItem from "./resource-item";

import "./index.less";

interface SyllabusProps {
  id: number;
  hasPurchased: boolean;
  buySubject: () => void;
  courseId: number;
  availableLanguages: any;
  defaultLanguage: any;
}

const ResourceItems = (props: any) => {
  const {
    resources,
    mcqs,
    courseId,
    subjectId,
    isUnlocked,
    language,
    isExpired,
    isPreviewable,
  } = props;

  if (!resources && !mcqs) return null;

  let list = [];

  if (resources && resources.length > 0) {
    list.push(
      resources.map((item: any) => (
        <ResourceItem
          type={item.file_extension}
          name={item.display_name}
          link={item.item_link ? item.item_link.url : null}
          key={item.display_name}
          isUnlocked={isUnlocked}
          isExpired={isExpired}
          isPreviewable = {isPreviewable}
        />
      ))
    );
  }

  if (mcqs && mcqs.length > 0) {
    list.push(
      mcqs.map((item: any) => (
        <ResourceItem
          type="mcq"
          name={`MCQ: ${item.title}`}
          link={`/courses/${courseId}/subjects/${subjectId}/mcq/${item.id}?language=${language}`}
          key={item.id}
          isUnlocked={isUnlocked}
          isExpired={isExpired}
          isPreviewable = {item.is_previewable}
        />
      ))
    );
  }

  return <>{list}</>;
};

const SyllabusItems = (props: any) => {
  const { syllabus, courseId, subjectId, language, isExpired } = props;

  if (syllabus) {
    if (syllabus.syllabus && syllabus.syllabus.length > 0) {
      const content = syllabus.syllabus
        .filter(
          (item: any) =>
            (item.syllabus_media && item.syllabus_media.length > 0) ||
            (item.mcqs_sets && item.mcqs_sets.length > 0)
        )
        .map((item: any) => ({
          title: item.title,
          body: (
            <ResourceItems
              resources={item.syllabus_media}
              mcqs={item.mcqs_sets}
              courseId={courseId}
              subjectId={subjectId}
              isUnlocked={item.is_unlocked}
              language={language}
              isExpired={isExpired}
              isPreviewable={item.is_previewable}
            />
          ),
        }));
      return <Collapsible content={content} />;
    }
  }

  return (
    <div className="syllabus-unavailable">
      Hello, Lurn team is still uploading the syllabus content for this subject.
      Please try again later. We're really sorry for the inconvenience.
    </div>
  );
};

const LanguageSelector = (props: any) => {
  const { availableLanguages, defaultLanguage, hasPurchased, onChange } = props;

  if (availableLanguages.length < 1) return null;

  const languages = availableLanguages.map((l: any) => ({
    label: l.language,
    value: l.key,
  }));
  return (
    <div className="language-selector__container">
      <Select
        options={languages}
        onChange={onChange}
        defaultValue={defaultLanguage}
        disabled={hasPurchased}
      />
    </div>
  );
};

const Syllabus = (props: SyllabusProps) => {
  const {
    id,
    hasPurchased,
    buySubject,
    courseId,
    availableLanguages,
    defaultLanguage,
  } = props;

  const [syllabus, setSyllabus] = useState<any>(null);
  const [busy, setBusy] = useState(true);
  const [batchExpired, setBatchExpired] = useState(true);

  const fetchSyllabus = async (language: any) => {
    try {
      setBusy(true);
      const res = await CourseAPI.getSyllabus(id, language);
      setSyllabus(res);
      checkBatchIsExpired(res);
      setBusy(false);
    } catch (error) {
      console.error(error);
      setBusy(false);
    }
  };

  const checkBatchIsExpired = (res: any) => {
    let difference_In_Time = 0;

    if (res.content.exam_badge != null) {
      if (res.content.exam_badge.end_date) {
        const today = new Date(Date.now());
        let today_string = today.toLocaleString("LK", { timeZone: "Asia/Colombo" })
        const expDate = new Date(res.content.exam_badge.end_date + "T00:00");
        //set purchased date time / difference
        difference_In_Time = expDate.getTime() - new Date(today_string).getTime();

        if (difference_In_Time > 0) {
          setBatchExpired(false);
        } else {
          setBatchExpired(true);
        }
      } else {
        setBatchExpired(true);
      }
    } else {
      if (typeof res.content.exam_badge === "undefined") {
        setBatchExpired(false);
      } else {
        setBatchExpired(true);
      }
    }
  };

  useEffect(() => {
    fetchSyllabus(defaultLanguage);
  }, []);

  return (
    <div>
      <LanguageSelector
        availableLanguages={availableLanguages}
        defaultLanguage={defaultLanguage}
        hasPurchased={hasPurchased}
        onChange={fetchSyllabus}
      />
      <PurchaseBtn
        label="BUY THIS SUBJECT"
        id={id}
        hasPurchased={hasPurchased}
        purchase={buySubject}
        type="subject"
      />
      {busy ? (
        <Spinner />
      ) : (
        <div className="syllabus">
          <SyllabusItems
            syllabus={syllabus.content}
            courseId={courseId}
            subjectId={id}
            language={syllabus.language}
            isExpired={batchExpired && !hasPurchased}
          />
        </div>
      )}
    </div>
  );
};

export default Syllabus;

import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Row, Col, Divider, message } from "antd";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { PurchaseAPI } from "api";

import TitleArea from "components/common/content/title";
import { Heading } from "components/common/typography";
import { Breadcrumb } from "components/common/breadcrumb/types";
import Container from "components/common/section-container";
import Wrapper from "components/common/wrappers/inner-wrapper";
import FSL from "components/common/full-screen-loader";
import landingBg from "assets/images/home/home-landing-bg.jpg";
import { Radio, Button, Select, Input, Item } from "components/common/form";

import NumberWCommas from "utils/number-w-commas";
import constructRedirectURL from "utils/redirect-url";

import RoutesRegistry from "services/router/registry";
import RoutesGenerator, { Route } from "services/router/generator";

import "./styles.less";
import { Field, Formik, Form as FormikForm } from "formik";
import translate from "i18n/translate";

const Course = (props: any) => {
  const history = useHistory();
  const { match } = props;
  const { type, id } = match.params;
  const initialCodeValue = { affiliationCode: "" };

  const [purchaseOptions, setPurchaseOptions] = useState<any>(null);
  const [busy, setBusy] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState<any>({});
  const [canNext, setCanNext] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [affCodeValid, setAffCodeValid] = useState<string>("");
  const [affiliatedPrice, setAffiliatedPrice] = useState<number>(0);

  const defaultSelectedExamBatch = (exam_batches: any) => {
    const today = new Date(Date.now());
    let today_string = today.toLocaleString("LK", { timeZone: "Asia/Colombo" })

    for(let i = 0; i < exam_batches.length; i++){
      let expDate = new Date(exam_batches[i].end_date + "T00:00");
      let difference = expDate.getTime() - new Date(today_string).getTime()

      if(difference > 0){
        return exam_batches[i];
      }
    }

    return exam_batches[0]
  }

  useEffect(() => {
    const getPurchaseOptions = async () => {
      try {
        const res = await PurchaseAPI.getPurchaseOptions(type, Number(id));

        if(res.message == "You have already purchased this stage"){
          history.push(
            RoutesGenerator(Route.Dashboard),
          );
        }

        setPurchaseOptions(res.content);
        setSelectedOptions({
          batch:
            res.content.exam_badges && res.content.exam_badges.length > 0
              ? defaultSelectedExamBatch(res.content.exam_badges)
              : null,
          language:
            res.content.languages && res.content.languages.length > 0
              ? res.content.languages[0].key
              : null,
        });
        setBusy(false);
      } catch (error) {
        setBusy(false);
        console.log(error);
      }
    };

    getPurchaseOptions();
  }, []);

  useEffect(() => {
    if (
      selectedOptions.paymentMethod &&
      (selectedOptions.paymentType === "one_time" ||
        (selectedOptions.paymentType === "easy" &&
          selectedOptions.paymentPlan)) &&
      selectedOptions.batch &&
      selectedOptions.language
    ) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [selectedOptions]);

  const selectOptions = (e: any) => {
    const options = _.clone(selectedOptions);
    options[e.currentTarget.name] = e.currentTarget.value;

    if (e.currentTarget.name === "paymentType") {
      delete options.paymentPlan;
    }

    if (
      e.currentTarget.name === "paymentType" &&
      e.currentTarget.value === "one_time"
    ) {
      options.paymentPlan = _.find(purchaseOptions.installment_plans, {
        no_of_months: 1,
      });
    }
    options.batch = selectedOptions.batch;
    options.language = selectedOptions.language;
    setSelectedOptions(options);
  };

  const onSelectChange = (name: string, value: string) => {
    const options = _.clone(selectedOptions);
    if (name == "batch") {
      options[name] = purchaseOptions.exam_badges.filter(
        (batch: any) => batch.id === value
      )[0];
    } else {
      options[name] = value;
    }

    setSelectedOptions(options);
  };

  const handleResetPromoCode = () => {
    setAffiliatedPrice(0);
  };

  const handleNext = async () => {
    setIsSubmitting(true);
    const query = JSON.stringify({
      ...selectedOptions,
      type,
      id,
      installmentPlan: selectedOptions.paymentPlan.no_of_months,
      batch: selectedOptions.batch.id,
      affiliationCode: affCodeValid != "" ? affCodeValid : ""
    });

    if (selectedOptions.paymentMethod === "card") {
      const url = constructRedirectURL(match.params, {
        stage_or_subject_id: id,
        installment_plan_id: selectedOptions.paymentPlan.id,
        stage_or_subject: type,
        exam_badge_id: selectedOptions.batch.id,
        purchase_lan: selectedOptions.language,
        aff_code: affCodeValid != "" ? affCodeValid : ""
      });
      window.location.href = url;
    }
    else if(selectedOptions.paymentMethod === "trial"){
      const params = {
        id: id,
        paymentPlan: { id: selectedOptions.paymentPlan.id },
        type: type,
        batch: selectedOptions.batch.id,
        language: selectedOptions.language,
        affiliationCode: affCodeValid != "" ? affCodeValid : ""
      }

      const res = await PurchaseAPI.purchaseFreetrial(params)
      
      if (res.status){
        history.push(
          `${RoutesGenerator(Route.Dashboard)}`
        );
      }
    }else {
      history.push(
        `${RoutesGenerator(Route.BankPayment, { type, id })}?options=${query}`
      );
    }
  };

  if (busy) {
    return <FSL />;
  }

  const crumbs: Breadcrumb[] = [
    RoutesRegistry.Courses,
    {
      title: RoutesRegistry.Courses.Course.title,
      path: `${RoutesRegistry.Courses.path}/${purchaseOptions.breadcrumbs.course_id}`,
    },
  ];

  if (type === "subject") {
    crumbs.push({
      title: RoutesRegistry.Courses.Course.Subject.title,
      path: `${RoutesRegistry.Courses.path}/${purchaseOptions.breadcrumbs.course_id}/subjects/${purchaseOptions.breadcrumbs.subject_id}`,
    });
  }

  crumbs.push({
    title: "Purchase",
  });

  const checkIsExpired = (batch : any) => {

    if(batch && batch.end_date){
      let difference_In_Time = 0;
      const today = new Date(Date.now());
      let today_string = today.toLocaleString("LK", { timeZone: "Asia/Colombo" })
      const expDate = new Date(batch.end_date + "T00:00");

      difference_In_Time = expDate.getTime() - new Date(today_string).getTime();
      
      if (difference_In_Time > 0) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  };

  const batches = purchaseOptions.exam_badges
    .filter((batch: any) => checkIsExpired(batch) != true)
    .map((batch: any) => {
      return ({
        value: batch.id,
        label: localStorage.getItem('defaultLang') == 'lk' ? batch.name_sinhala : batch.name,
      });
  });

  const languages = purchaseOptions.languages
    .filter((language: any) => language.enabled)
    .map((language: any) => ({
      value: language.key,
      label: language.language,
    }));

  const paymentTypes = [
    {
      label: localStorage.getItem('defaultLang') == 'lk' ? "එකවර ගෙවීම" : "One Time Payment",
      key: "one_time",
      enabled: !!_.find(purchaseOptions.installment_plans, { no_of_months: 1 }),
    },
    {
      label: localStorage.getItem('defaultLang') == 'lk' ? "LURN පහසු ගෙවීමේ සැලැස්ම" : "LURN Easy Payment Plan",
      key: "easy",
      enabled:
        purchaseOptions.installment_plans.filter(
          (plan: any) => plan.no_of_months !== 1
        ).length > 0,
    },
  ];

  const getDiscount = () =>
    selectedOptions.batch.original_price -
    selectedOptions.batch.discounted_price;

  const oneTimeDiscount = () => {
    if (
      selectedOptions &&
      selectedOptions.paymentType === "one_time" &&
      selectedOptions.paymentPlan.no_of_months === 1
    ){
      //has discount
      return selectedOptions.batch.one_time_payment_price
    }else{
      return '0'
    }
  };

  const getCurrentTotal = () => {
    if (selectedOptions.paymentType) {
      if (
        selectedOptions.paymentType === "easy" &&
        selectedOptions.paymentPlan &&
        selectedOptions.paymentPlan.no_of_months
      ) {
        if (selectedOptions.batch.discounted_price) {
          if(affCodeValid != ""){
            return (selectedOptions.batch.discounted_price - affiliatedPrice);
          }
          return selectedOptions.batch.discounted_price;
        }
        if(affCodeValid != ""){
          return (selectedOptions.batch.original_price - affiliatedPrice);
        }
        return selectedOptions.batch.original_price;
      }

      // if one_time_payment
      if (selectedOptions.paymentType === "one_time" && selectedOptions.paymentPlan.no_of_months === 1) {
        if (selectedOptions.batch.discounted_price) {
          if(affCodeValid != "") {
            return selectedOptions.batch.discounted_price - selectedOptions.batch.one_time_payment_price - affiliatedPrice
          }
          return (selectedOptions.batch.discounted_price - selectedOptions.batch.one_time_payment_price)
        }

        if(affCodeValid != "") {
          return (selectedOptions.batch.original_price - selectedOptions.batch.one_time_payment_price - affiliatedPrice)
        }

        return (selectedOptions.batch.original_price - selectedOptions.batch.one_time_payment_price)
      }
    }

    if(affCodeValid != ""){
      return (selectedOptions.batch.discounted_price - affiliatedPrice);
    }
    return selectedOptions.batch.discounted_price;
  };

  const getTotal = () => {
    const currentTotal = getCurrentTotal();

    if (selectedOptions.batch.purchased_payment_price) {
      const refundedTotal =
        currentTotal - selectedOptions.batch.purchased_payment_price;
      if (refundedTotal < 0) {
        return 0;
      }
      return refundedTotal;
    }

    return (currentTotal > 0 ? currentTotal : 0);
  };

  const getInstallment = () => {
    return getTotal() / selectedOptions.paymentPlan.no_of_months;
  };

  const affiliationValidationSchema = Yup.object().shape({
    affiliationCode: Yup.string().required("Please add an promo code."),
  });

  return (
    <>
      <TitleArea
        backgroundImg={landingBg}
        title={translate('paymentOptions.heading')}
        icon="charted-acc"
        breadcrumbs={crumbs}
      />
      <section className="section-container">
        <Container>
          <Row gutter={24} type="flex">
            <Col md={16}>
              <Wrapper className="purchase-wrapper">
                <p className="section-labels">{translate('paymentOptions.paymentMethod')}</p>
                <form>
                  {purchaseOptions.options.map((option: any) => (
                    <Radio
                      label={option.label}
                      value={option.key}
                      name="paymentMethod"
                      disabled={!option.enabled}
                      key={option.key}
                      onChange={selectOptions}
                    />
                  ))}
                </form>
              </Wrapper>
              <Wrapper className="purchase-wrapper">
                <p className="section-labels">{translate('paymentOptions.promo.title')}</p>
                <Formik
                  initialValues={initialCodeValue}
                  validationSchema={affiliationValidationSchema}
                  onSubmit={async ({ affiliationCode }, { setSubmitting }) => {
                    try {
                      let res = await PurchaseAPI.validateAffiliationCode(
                        type,
                        Number(id),
                        affiliationCode
                      );

                      const affCodeResponse = res.content.exam_badges[0].affiliate_code
                      const affCodeResponsePrice = res.content.exam_badges[0].affiliate_price

                      if(affCodeResponse == null || affCodeResponse == "invalid code"){
                        setAffCodeValid("");
                        setAffiliatedPrice(0);
                        message.warn(
                          "Invalid Promo Code!"
                        );
                      }else{ //valid
                        setAffCodeValid(affCodeResponse);
                        setAffiliatedPrice(affCodeResponsePrice);
                        message.success(
                          "Promo Code Verified!"
                        );
                      }

                      setSubmitting(false);
                    } catch (error) {
                      setSubmitting(false);
                      message.warn(
                        "Error!"
                      );
                    }
                  }}
                >
                  {({ errors, touched, isSubmitting }) => (
                    <FormikForm noValidate>
                      <Item>
                        <Field
                          type="text"
                          name="affiliationCode"
                          // label="Affiliation Code"
                          // touched={touched.affiliationCode}
                          // disabled={affCodeValid != "" || isSubmitting ? true:false}
                          error={errors.affiliationCode}
                          as={Input}
                        />
                      </Item>
                      <div className="affiliation-btn">
                        <Button
                          htmlType="submit"
                          fullWidth={false}
                          loading={isSubmitting}
                          size="small"
                          // disabled={affCodeValid != "" || isSubmitting ? true:false}
                        >
                          {translate('paymentOptions.promo.applyBtn')}
                        </Button>
                      </div>
                    </FormikForm>
                  )}
                </Formik>
              </Wrapper>
              <Wrapper className="purchase-wrapper">
                <p className="section-labels">{translate('paymentOptions.paymentPlan.title')}</p>
                <form>
                  {paymentTypes.map((type: any) => (
                    <Radio
                      label={type.label}
                      value={type.key}
                      name="paymentType"
                      disabled={!type.enabled}
                      key={type.key}
                      onChange={selectOptions}
                    />
                  ))}
                  {selectedOptions.paymentType === "easy" ? (
                    <Select
                      placeholder={translate('paymentOptions.paymentPlan.placeholder')}
                      options={purchaseOptions.installment_plans
                        .filter((plan: any) => plan.no_of_months !== 1)
                        .map((plan: any) => ({
                          label: plan.title,
                          value: plan,
                          disabled: !plan.enabled,
                        }))}
                      onChange={(e) => onSelectChange("paymentPlan", e)}
                    />
                  ) : null}
                </form>
              </Wrapper>
              <Wrapper className="purchase-wrapper">
                <p className="section-labels">{translate('paymentOptions.examBatch')}</p>
                <form>
                {
                  batches.length > 0 ?
                    <Select
                      placeholder={translate('paymentOptions.examBatchPlaceholder')}
                      options={batches}
                      disabled={batches && batches.length == 1}
                      onChange={(e) => onSelectChange("batch", e)}
                      defaultValue={
                        selectedOptions.batch ? selectedOptions.batch.id : null
                      }
                    />
                  :
                    <div className="installment__status waiting">
                      {translate('paymentOptions.examBatchWarn')}
                    </div>
                }
                </form>
              </Wrapper>
              <Wrapper className="purchase-wrapper">
                <p className="section-labels">{translate('paymentOptions.language')}</p>
                <form>
                  <Select
                    placeholder={translate('paymentOptions.languagePlaceholder')}
                    options={languages}
                    disabled={languages && languages.length == 1}
                    onChange={(e) => onSelectChange("language", e)}
                    defaultValue={
                      selectedOptions.language ? selectedOptions.language : null
                    }
                  />
                </form>
              </Wrapper>
            </Col>
            <Col md={8}>
              <Wrapper className="purchase-summary">
                <Heading type={3}>{translate('paymentOptions.summary.title')}</Heading>
                <Divider />
                <Heading type={4}>{translate('paymentOptions.summary.item')}</Heading>
                <p>
                  {purchaseOptions.summary.course.title}:{" "}
                  {purchaseOptions.summary.subject
                    ? purchaseOptions.summary.subject.title
                    : purchaseOptions.summary.stage.title}
                </p>
                {selectedOptions.batch ? (
                  <>
                    <Divider />
                    <div className="purchase-summary__table">
                      <p>{translate('paymentOptions.summary.originalPrice')}</p>
                      <p>
                        Rs.{" "}
                        {NumberWCommas(selectedOptions.batch.original_price)}
                      </p>
                    </div>

                    {selectedOptions.batch.discounted_price ? (
                      <div className="purchase-summary__table">
                        <p>{translate('paymentOptions.summary.discount')}</p>
                        <p>Rs. {NumberWCommas(getDiscount())}</p>
                      </div>
                    ) : null}

                    {selectedOptions.batch.purchased_payment_price ? (
                      <div className="purchase-summary__table">
                        <p>{translate('paymentOptions.summary.ownSubRefund')}</p>
                        <p>
                          Rs.{" "}
                          {NumberWCommas(
                            selectedOptions.batch.purchased_payment_price
                          )}
                        </p>
                      </div>
                    ) : null}

                    {/* Promo Discount */}
                    {
                      affCodeValid != "" && //affiliation code is valid and present
                    (
                      <div className="purchase-summary__table">
                        <p>{translate('paymentOptions.summary.promoDiscount')} </p>
                        <p>Rs. {NumberWCommas(affiliatedPrice)}</p>
                      </div>
                    )}

                    {selectedOptions.paymentType === "one_time" &&
                    selectedOptions.paymentPlan.no_of_months === 1 ? (
                      <div className="purchase-summary__table">
                        <p>{translate('paymentOptions.summary.onetimeDiscount')}</p>
                        <p>Rs. {NumberWCommas(oneTimeDiscount())}</p>
                      </div>
                    ) : null}

                    <Divider />

                    <div className="purchase-summary__table">
                      <Heading type={4}>{translate('paymentOptions.summary.total')}</Heading>
                      <p>Rs. {NumberWCommas(getTotal())}</p>
                    </div>

                    {selectedOptions.paymentType === "easy" &&
                    selectedOptions.paymentPlan ? (
                      <>
                        <Divider />
                        <div className="purchase-summary__table">
                          <p>{translate('paymentOptions.summary.noOfInstallments')}</p>
                          <p>{selectedOptions.paymentPlan.no_of_months}</p>
                        </div>
                        <div className="purchase-summary__table">
                          <p>{translate('paymentOptions.summary.installmentPrice')}</p>
                          <p>Rs. {NumberWCommas(getInstallment())}</p>
                        </div>
                      </>
                    ) : null}
                  </>
                ) : null}
              </Wrapper>
            </Col>
          </Row>
          <Row gutter={24} type="flex">
           {/* <Col md={16}>
              <Button
                size="large"
                className="next-btn"
                onClick={handleResetPromoCode}

              >
                Reset Promo Code
              </Button>
            </Col> */}
            <Col md={16}>
              <Button
                size="large"
                className="next-btn"
                disabled={!canNext}
                onClick={handleNext}
                loading={isSubmitting}
              >
                {translate('paymentOptions.nextBtn')}
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Course;

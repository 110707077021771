import React, { useEffect, useState } from "react";

import Spinner from "components/common/spinner";
import PurchaseBtn from "components/common/course/purchase";
import Collapsible from "components/common/collapsible";
import { Select } from "components/common/form";

import { CourseAPI } from "api";

import ResourceItem from "../syllabus/resource-item";
import LiveSessionIndicator from "./live_session_indicator"
import { Heading } from "components/common/typography";

import "../syllabus/index.less";

interface SyllabusProps {
  id: number;
  hasPurchased: boolean;
  buySubject: () => void;
  courseId: number;
  availableLanguages: any;
  defaultLanguage: any;
}

type SessionType = {
  title: String
  meeting_link: String
}

const ResourceItems = (props: any) => {
  const {
    resources,
    mcqs,
    courseId,
    subjectId,
    isUnlocked,
    language,
    isExpired,
    isPreviewable,
  } = props;

  if (!resources) return null;

  let list = [];

  if (resources && resources.length > 0) {
    list.push(
      resources.map((item: any) => (
        <ResourceItem
          type={item.file_extension}
          name={item.display_name}
          link={item.item_link ? item.item_link.url : null}
          key={item.display_name}
          isUnlocked={isUnlocked}
          isExpired={isExpired}
          isPreviewable = {isPreviewable}
        />
      ))
    );
  }

  return <>{list}</>;
};

const LiveSessionsList = (props: any) => {
  const { liveSessions, courseId, subjectId, language, isExpired } = props;

  if (liveSessions) {
    if (liveSessions.syllabus && liveSessions.syllabus.length > 0) {
      const content = liveSessions.syllabus
        .filter((item: any) => (item.syllabus_media && item.syllabus_media.length > 0))
        .map((item: any) => ({
          title: item.title,
          body: (
            <ResourceItems
              resources={item.syllabus_media}
              courseId={courseId}
              subjectId={subjectId}
              isUnlocked={item.is_unlocked}
              language={language}
              isExpired={isExpired}
              isPreviewable={item.is_previewable}
            />
          ),
        }));
      return <Collapsible content={content} />;
    }
  }

  return (
    <div className="syllabus-unavailable">
      Hello, Lurn team is still uploading the recordings for this subject.
      Please try again later. We're really sorry for the inconvenience.
    </div>
  );
};

// const LanguageSelector = (props: any) => {
//   const { availableLanguages, defaultLanguage, hasPurchased, onChange } = props;

//   if (availableLanguages.length < 1) return null;

//   const languages = availableLanguages.map((l: any) => ({
//     label: l.language,
//     value: l.key,
//   }));
//   return (
//     <div className="language-selector__container">
//       <Select
//         options={languages}
//         onChange={onChange}
//         defaultValue={defaultLanguage}
//         disabled={hasPurchased}
//       />
//     </div>
//   );
// };

const LiveSessions = (props: SyllabusProps) => {
  const {
    id,
    hasPurchased,
    buySubject,
    courseId,
    availableLanguages,
    defaultLanguage,
  } = props;

  const [liveSessions, setLiveSessions] = useState<any>(null);
  const [busy, setBusy] = useState(true);
  const [batchExpired, setBatchExpired] = useState(true);
  const [onGoingMeetings, setOnGoingMeetings] = useState([])

  const fetchLiveSessions = async (language: any) => {
    try {
      setBusy(true);
      const res = await CourseAPI.getLiveSessions(id, language);
      setLiveSessions(res);
      setOnGoingMeetings(res.content.on_going_live_sessions)
      checkBatchIsExpired(res);
      setBusy(false);
    } catch (error) {
      console.error(error);
      setBusy(false);
    }
  };

  const checkBatchIsExpired = (res: any) => {
    let difference_In_Time = 0;
    let today = new Date(Date.now());

    if (res.content.exam_badge != null) {
      if (res.content.exam_badge.end_date) {
        let today_string = today.toLocaleString("LK", { timeZone: "Asia/Colombo" })
        const expDate = new Date(res.content.exam_badge.end_date + "T00:00");

        difference_In_Time = expDate.getTime() - new Date(today_string).getTime();

        if (difference_In_Time > 0) {
          setBatchExpired(false);
        } else {
          setBatchExpired(true);
        }
      } else {
        setBatchExpired(true);
      }
    } else {
      if (typeof res.content.exam_badge === "undefined") {
        setBatchExpired(false);
      } else {
        setBatchExpired(true);
      }
    }
  };

  useEffect(() => {
    fetchLiveSessions(defaultLanguage);
  }, []);

  return (
    <div>
      {/* <LanguageSelector
        availableLanguages={availableLanguages}
        defaultLanguage={defaultLanguage}
        hasPurchased={hasPurchased}
        onChange={fetchSyllabus}
      /> */}

      { 
        onGoingMeetings.map((session: SessionType) => {
          return <LiveSessionIndicator title={session.title} meeting_link={session.meeting_link}/>
        })
      }

      <PurchaseBtn
        label="BUY THIS SUBJECT"
        id={id}
        hasPurchased={hasPurchased}
        purchase={buySubject}
        type="subject"
      />
      {busy ? (
        <Spinner />
      ) : (
        <div className="syllabus">
          <Heading type={3}>Live Session Recordings</Heading>
          <br></br>
          <LiveSessionsList
            liveSessions={liveSessions.content}
            courseId={courseId}
            subjectId={id}
            language={liveSessions.language}
            isExpired={batchExpired && !hasPurchased}
          />
        </div>
      )}
    </div>
  );
};

export default LiveSessions;

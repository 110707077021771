import React from "react";
import { Row, Col } from "antd";

import { Heading } from "components/common/typography";
import SocialShare from "./social-share";

import "./styles.less";

const OverviewItem = (props: any) => {
  const { topic, content } = props;

  return (
    <div className="overview-item">
      <Heading type={4}>{topic}</Heading>
      <p>{content}</p>
    </div>
  );
};

const checkBatchIsExpired = (exam_badges: any) => {
  let difference_In_Time = 0;

  let final_map = "";

  exam_badges.map((exam_badge: any) => {
    if (exam_badge != null) {
      if (exam_badge.end_date) {
        const today = new Date(Date.now());
        let today_string = today.toLocaleString("LK", { timeZone: "Asia/Colombo" })
        const expDate = new Date(exam_badge.end_date + "T00:00");

        difference_In_Time = expDate.getTime() - new Date(today_string).getTime();

        if (difference_In_Time > 0) {
          if(final_map != ""){
            final_map = final_map + " | " + exam_badge.name;
          }else{
            final_map = exam_badge.name;
          }
        }
      }
    }
  })

  return final_map;
};

const SubjectOverview = (props: any) => {
  const { overview } = props;

  return (
    <div className="subject-overview">
      <OverviewItem topic="Course" content={overview.courseName} />
      <OverviewItem topic="Stage" content={overview.stageName} />
      <OverviewItem topic="Duration" content={`${overview.duration} Months`} />
      <OverviewItem
        topic="Available Languages"
        content={overview.availableLangs
          .map((lang: any) => lang.language)
          .join(" ")}
      />
      {overview.examBatch ? (
        <OverviewItem topic="Batch" content={overview.examBatch.name} />
      ) : (
        <OverviewItem
          topic="Available Batches"
          content={checkBatchIsExpired(overview.allBatch)}
        />
      )}
      <OverviewItem
        topic="Lecture Hours"
        content={`${overview.lectureHours} Hours`}
      />
      <OverviewItem topic="Retake Time" content={overview.retakeTime} />
      <OverviewItem topic="Share" content={<SocialShare />} />
    </div>
  );
};

const Overview = (props: any) => {
  const { data } = props;

  return (
    <Row gutter={[24, 0]}>
      <Col lg={18}>
        <div dangerouslySetInnerHTML={{ __html: data.description }} />
      </Col>
      <Col lg={6}>
        <SubjectOverview overview={data.overview} />
      </Col>
    </Row>
  );
};

export default Overview;

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { CourseAPI } from "api";

import TitleArea from "components/common/content/title";
import { Breadcrumb } from "components/common/breadcrumb/types";
import {
  Header as CourseHeader,
  PurchaseBtn,
  VideoContainer,
} from "components/common/course";
import Container from "components/common/section-container";
import { Tabs, TabPane } from "components/common/tabs";
import Spinner from "components/common/spinner";

import RoutesRegistry from "services/router/registry";
import RoutesGenerator, { Route } from "services/router/generator";

import landingBg from "assets/images/home/home-landing-bg.jpg";

import Overview from "./overview";
import LiveSessions from "./live-sessions";
import Lecturers from "./lecturers";
import Reviews from "./reviews";
import Syllabus from "./syllabus";
import RelatedSubjects from "./related-subjects";

import { UserAPI } from 'api';
import "./styles.less";

const TabPanes = (props: any) => {
  const { overview, subject, is_purchased_subject, buySubject, courseId } = props;

  return (
    <Tabs defaultActiveKey="1" className="tabs" animated={false}>
      <TabPane tab="Overview" key="1">
        <Overview data={overview} />
      </TabPane>
      
      <TabPane tab="Syllabus" key="2">
        <Syllabus
          id={subject.id}
          hasPurchased={is_purchased_subject}
          buySubject={buySubject}
          courseId={courseId}
          availableLanguages={subject.available_languages}
          defaultLanguage={
            subject.purchased_lan
              ? subject.purchased_lan
              : subject.available_languages[0].key
          }
        />
      </TabPane>

      <TabPane tab="Live Sessions" key="3">
        <LiveSessions
            id={subject.id}
            hasPurchased={is_purchased_subject}
            buySubject={buySubject}
            courseId={courseId}
            availableLanguages={subject.available_languages}
            defaultLanguage={
              subject.purchased_lan
                ? subject.purchased_lan
                : subject.available_languages[0].key
            }
          />
      </TabPane>

      <TabPane tab="Lecturers" key="4">
        <Lecturers id={subject.id} />
      </TabPane>
      <TabPane tab="Reviews" key="5">
        <Reviews id={subject.id} hasPurchased={subject.is_purchased} />
      </TabPane>
    </Tabs>
  );
};

const Subject = (props: any) => {
  const history = useHistory();
  const { match } = props;
  const [subject, setSubject] = useState<any>(null);
  const [busy, setBusy] = useState(true);
  const { cid, sid } = match.params;
  const [is_purchased_subject, setIsPurchasedSubject] = useState<boolean>(false);

  const crumbs: Breadcrumb[] = [
    RoutesRegistry.Courses,
    {
      title: RoutesRegistry.Courses.Course.title,
      path: `${RoutesRegistry.Courses.path}/${cid}`,
    },
    {
      title: RoutesRegistry.Courses.Course.Subject.title,
    },
  ];

  const checkIsExpired = (date : any) => {
    let difference_In_Time = 0;
    const today = new Date(Date.now());
    let today_string = today.toLocaleString("LK", { timeZone: "Asia/Colombo" })
    const expDate = new Date(date + "T00:00");

    difference_In_Time = expDate.getTime() - new Date(today_string).getTime();
    
    if (difference_In_Time > 0) {
      return false;
    } else {
      return true;
    }

  };

  const getMyCourses = async (subjectData : any) => {
    try {
      const res = await UserAPI.getMyOrders();
      for (let order of res.content.orders) {

        let stage_subject_id = order.stage_or_subject_id;
        const res = await CourseAPI.course(Number(cid));

        for(let stageData of res.content.stages){
          // if stage
          if(order.stage_or_subject == 'stage' && stageData.id == stage_subject_id){
            for(let stageSubjectData of stageData.subjects){
              if(stageSubjectData.id == subjectData.id){
                //check expired batch
                if(stageSubjectData.purchased_exam_badge && !checkIsExpired(stageSubjectData.purchased_exam_badge.end_date)){
                  if(!order.order_cancel){
                    setIsPurchasedSubject(true);
                  }
                }
                break;
              }
            }
          }
          //if subject
          else{
            for(let stageSubjectData of stageData.subjects){
              if(stageSubjectData.id == subjectData.id && subjectData.id == stage_subject_id && order.stage_or_subject == 'subject'){
                //check expired batch
                if(stageSubjectData.purchased_exam_badge && !checkIsExpired(stageSubjectData.purchased_exam_badge.end_date)){
                  if(!order.order_cancel){
                    setIsPurchasedSubject(true);
                  }
                }
                break;
              }
            }
          }
        }

      };
      setBusy(false);
    } catch (error) {
      setBusy(false);
      console.log(error);
    }
  };

  useEffect(() => {
    const getCourses = async () => {
      try {
        const res = await CourseAPI.subject(Number(sid));
        setSubject(res.content);
        getMyCourses(res.content);
      } catch (error) {
        setBusy(false);
        console.log(error);
      }
    };

    getCourses();
  }, [sid, cid]);

  const Content = () => {
    if (busy) {
      return <Spinner />;
    }

    const overview = {
      overview: {
        courseName: subject.course_details.course_title,
        stageName: subject.course_details.stage_title,
        duration: subject.duration,
        availableLangs: subject.available_languages,
        examBatch: subject.purchased_exam_badge,
        allBatch: subject.course_details.exam_badges,
        lectureHours: subject.lecture_hours,
        retakeTime: subject.retake_time,
      },
      description: subject.overview,
    };

    const buy = async (subjectId: number) => {
      history.push(
        RoutesGenerator(Route.PaymentOptions, {
          type: "subject",
          id: subjectId,
        })
      );
    };

    const goToStage = () => {
      history.push(
        `${RoutesGenerator(Route.Course, {
          id: subject.course_details.course_id,
        })}?stage=${subject.course_details.stage_id}`
      );
    };

    return (
      <>
        <Container className="subject-detail-container" startFromTop>
          <CourseHeader
            title={subject.title}
            price={subject.original_price}
            discountedPrice={subject.discounted_price}
            className="subject-header"
            hasPurchased={subject.is_purchased}
            easyPayAvailable={subject.easy_pay_available}
            ratings={{
              rating: subject.rating,
              count: subject.rating_count,
            }}
            isOneSubjectPurchased={is_purchased_subject}
            actionBtn={
              <PurchaseBtn
                label="BUY THIS SUBJECT"
                id={subject.id}
                hasPurchased={is_purchased_subject}
                purchase={buy}
                goToStage={goToStage}
                type="subject"
              />
            }
          />
          <VideoContainer
            imageUrl={subject.image.url}
            videoUrl={subject.video_url}
            showVideo={subject.show_video}
          />
          <TabPanes
            subject={subject}
            is_purchased_subject={is_purchased_subject}
            overview={overview}
            buySubject={buy}
            courseId={cid}
          />
        </Container>
        {subject.related_subjects ? (
          <RelatedSubjects subjects={subject.related_subjects} />
        ) : null}
      </>
    );
  };

  return (
    <>
      <TitleArea backgroundImg={landingBg} breadcrumbs={crumbs} />
      <Content />
    </>
  );
};

export default Subject;
